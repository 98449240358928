.message-block {

  .title {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiDialogTitle-root {
    h2 {
      margin: 0;
      font-size: 24px;
    }
  }

  .MuiDialogActions-root {
    button {
      span {
        color: $pink;
        font-size: 14px;
      }
    }
  }

}

