.component + .component {
	margin-top: 3rem;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 1rem;
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .MuiInputBase-root {
    &::before {
      border-color: rgba(0, 0, 0, 0.42);
    }
  }
  .select-field {
    font-size: 14px;
    margin-right: 1rem;
    min-width: 200px;
    .MuiLabel-root {
      font-size: 14px;
    }
  }

	.autocomplete-field {
		width: 100%;
		input {
			font-size: 14px;
		}
	}
}

.MuiPaper-root {
	.MuiList-root {
		li {
			font-size: 14px;
		}
		.Mui-selected {
			color: #FF4081;
		}
	}
}

.MuiAutocomplete-popper {
	margin-top: 20px;
	.MuiAutocomplete-paper {
		.MuiAutocomplete-listbox {
			.MuiAutocomplete-option {
				color: rgba(0, 0, 0, 0.87);
				font-size: 16px;
				&:hover {
					background: rgba(0,0,0,0.08);
				}
			}
		}
	}
}

.content-wrapper {
	font-weight: 300;
	padding: 0 2rem;

	em {
		font-weight: 700;
		font-style: normal;
	}
}

.procedure-list-component {
	padding: 0 2rem;
	.no-procedure-block {
		color: rgba(0, 0, 0, 0.54);
		font-size: 14px;
		font-weight: 500;
	}
	table {
		thead {
			tr {
				th {
					padding: 5px 0 5px 0 !important;
				}
			}
		}
		tr {
			td {
				padding: 0;
			}
			div {
				white-space: nowrap;
				width: 180px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:last-child {
				text-align: center;
			}
		}
	}
}

.submit-component {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	ul {
		li {
			label {
				font-size: 14px;
				color: #c1bfbd;
			}
			div {
				width: 100%;
				.MuiOutlinedInput-root {
					font-size: 14px;
				}
			}
			textarea {
				font-size: 14px;
			}
			.MuiInputBase-root {
				font-size: 14px;
			}
			.MuiFormControl-root {
				label {
					font-size: 18px;
				}
			}
		}
	}
	button {
		cursor: pointer;
		font-size: 14px;
		font-weight: 500;
		border-radius: 2px;
		margin: 2rem auto;
		color: #fff;
		background-color: $pink !important;
		text-transform: uppercase;
		padding: 6px 16px;
		box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
		&:hover {
			opacity: 0.75;
		}
		&:disabled {
			background-color: #fff !important;
		}
	}
}

.excluded-price {
	display: block;
	margin-top: 20px;
}

.discount-value-component {
  display: flex;
  align-items: center;
  .MuiInputBase-root {
    &::before {
      border-color: #000;
    }
  }
  input {
    font-size: 14px;
  }
  .discount-label {
    margin-right: 1rem;
  }

	.discount-value {
		width: 80px !important;
	}

	.discount-type {
		display: flex;
		flex-direction: row;
		margin-left: 10px;

		label {
			font-size: 14px;
			.MuiTouchRipple-root {
				display: none;
			}
		}

		.MuiFormControlLabel-root {
			span {
				font-size: 14px;
				&:hover {
					background: transparent;
				}
			}
		}
		svg {
			width: 25px;
			height: 25px;
		}
		.Mui-checked {
			color: $pink;
		}
	}
}

.discounted-price {
	text-decoration: line-through;
}

h3 {
	margin: 0.7rem 0 0 0.5rem;
	color: #575757;
	font-size: 1.7rem;
}

.narrow-column {
	width: 100px;
}

.mainWrapper {
	.MuiPaper-elevation1 {
		padding: 1rem 2rem 2rem;
		margin: 0 0 2rem;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;

		h3 {
			margin-bottom: 15px;
		}
	}
}

.form-wrapper {
	width: 100%;
	list-style-type: none;
	padding: 0 1.5rem;
	margin: 0;

	li {
		min-height: 6rem;
		display: flex;
		align-items: center;
		margin-bottom: 15px;

    input {
      font-size: 14px;
    }
    .MuiFormHelperText-root {
      font-size: 10px;
    }
    .MuiInputBase-root {
      &::before {
        border-color: rgba(0, 0, 0, 0.42);
      }
    }
    .custom-select-wrapper {
      label {
        left: -14px;
      }
    }
    .custom-date-wrapper {
      label {
        left: -14px;
      }
      .MuiInputBase-root {
        border-radius: 0;
        input {
          padding: 13px 0 5px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 0 0 1px 0;
          border-color: rgba(0, 0, 0, 0.42);
        }
      }
    }
  }
}

.MuiPickersBasePicker-container {
	.MuiToolbar-regular {
		button {
			h6 {
				font-size: 16px;
			}
			h4 {
				font-size: 34px;
			}
		}
	}
}

.MuiInput-Underline,.Mui-focused,.MuiInputBase-root {
	&:after {
		border-bottom: 2px solid $pink !important;
	}
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused {
	color: $pink;
}

.MuiDialogActions-spacing {
	button {
		font-size: 16px;
		color: $pink;
	}
}
.MuiPickersToolbar-toolbar {
	background-color: $pink !important;
}
.MuiPickersBasePicker-pickerView {
  .MuiPickersCalendar-transitionContainer {
    .MuiPickersCalendar-week {
      div {
        .MuiPickersDay-day {
          span {
            p {
              font-size: 16px;
            }
          }
        }
        .MuiPickersDay-daySelected {
          background-color: $pink !important;
          color: #fff !important;
        }
      }
    }
  }
  .MuiPickersCalendarHeader-switchHeader {
    .MuiPickersSlideTransition-transitionContainer {
      p {
        font-size: 16px;
      }
    }
  }
  .MuiPickersCalendarHeader-daysHeader {
    span {
      font-size: 14px;
    }
  }
}
.checkbox-wrapper {
	display: flex;
	align-items: center;
	margin: 4rem auto 0;
	max-width: 40rem;
	span {
		&:hover {
			background: transparent;
		}
	}
	label {
		span {
			font-size: 16px;
		}
	}
	.Mui-checked {
		color: $pink !important;
	}
	.email-checkbox {
		svg {
			width: 25px;
			height: 25px;
		}
	}
	.MuiLabel-root {
		font-size: 14px;
	}
	.MuiTouchRipple-root {
		display: none;
	}
}
.modal-wrapper {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      h2 {
        font-size: 18px;
      }
    }
    .MuiSelect-select {
      font-size: 15px;
      line-height: 16px;
    }
    .MuiDialogContent-root {
      .MuiChip-root {
        .MuiChip-label {
          font-size: 13px;
          input {
            font-size: 14px;
          }
        }
      }
    }
    label {
      font-size: 15px;
    }
    .MuiTextField-root {
      margin-bottom: 1rem;
      label {
        font-size: 14px;
      }
    }

    .MuiInput-root {
      margin-bottom: 10px;
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        font-size: 18px;
      }
    }

    .select-component {
      margin-bottom: 1rem;
      .MuiFormControl-root label {
        transform: translate(0, 0) scale(0.75);
      }

      .chips {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }

    .check-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      span {
        font-size: 14px;
        &:hover {
          background: transparent;
        }
      }
      svg {
        width: 25px;
        height: 25px;
      }
      label {
        font-size: 14px;
      }
      .Mui-checked {
        color: $pink;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
    button {
      color: $pink;
    }
    button,
    input[type="number"],
    textarea {
      font-size: 14px;
      padding: 7px 0;
    }
  }
}

.MuiPaper-root {
	.MuiList-root {
		li {
			span {
				font-size: 14px;
				&:hover {
					background: transparent;
				}
			}
			svg {
				width: 25px;
				height: 25px;
			}
			.Mui-checked {
				color: $pink;
			}
		}
	}
}
.MuiModal-root {
  .MuiDialog-container {
    .MuiDialogContent-root {
        .procedure-scheduling{
            .procedure-scheduling {
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
            .MuiPaper-elevation {
                padding: 0;
                box-shadow: none;
                .MuiButtonBase-root {
                    .MuiAccordionSummary-content{
                        margin: 0;
                    }
                }
                .MuiFormControlLabel-root {
                    .MuiRadio-root {
                        &.Mui-checked {
                            color: $pink;
                            font-size: 14px;
                        }
                    }
                    .MuiTypography-body1 {
                        font-size: 14px;
                    }
                }
            }
        }
    
      .MuiPickersLayout-root {
        .MuiPickersToolbar-root {
          background: $pink;
          .MuiTypography-overline {
            font-size: 13px;
            color: #fff;
          }
          .MuiGrid-root {
            h4 {
              color: #fff;
            }
          }
        }
        .MuiPickersLayout-contentWrapper {
          .MuiButtonBase-root {
            font-size: 14px;
            &.MuiPickersDay-today:not(.Mui-selected) {
              border: none;
              background: transparent;
              color: $pink;
            }
            &.MuiPickersDay-today,
            &.Mui-selected {
              background: $pink;
              color: #fff;
            }
          }
          .MuiDayCalendar-header {
            .MuiDayCalendar-weekDayLabel {
              font-size: 14px;
            }
          }
          .MuiPickersCalendarHeader-root {
            .MuiPickersCalendarHeader-labelContainer {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
