.form-error {
	color: red;
	padding: 1rem 0;
}
.login-form-wrapper {
	padding: 1rem 2rem 2rem;
	margin:  7rem auto 2rem;
	max-width: 500px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px !important;
}

.login-form {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.MuiFormControl-root {
		margin-bottom: 12px;
		label {
			font-size: 15px;
		}
		input {
			font-size: 15px;
		}
	}

	.login-form-submit {
		max-width: 250px;
		margin: 1rem auto;
		font-size: 15px;
	}
}