.table-container {
  table {
    min-width: 650px;

    thead {
      tr {
        th {
          font-size: 14px;
          color: #9e9e9e;
          &:last-child {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          .icon-block {
            cursor: pointer;
            width: 100%;
            button {
              color: #000;
            }
            .material-icons {
              margin-right: 10px;
              position: relative;
              &:hover {
                .overlay {
                  position: absolute;
                  background: rgba(0, 0, 0, 0.1);
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-58%, -62%);
                }
              }
            }
            span {
              svg {
                width: 20px;
                height: 20px;
                &:first-child {
                  margin-right: 5px;
                }
              }
            }
          }

          &:last-child {
            text-align: center;
          }
          .button-block {
            a {
              text-decoration: none;
              button {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.87);
              }
            }
          }
        }
      }
    }
  }
}