.site-logo {
	display: block;
	text-align: center;
	margin: 4rem auto 4rem;

	img {
		height: auto;
        width: 500px;
	}
}

.main-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 7rem;

	.button-block {
		display: flex;

		.all-report-button {
			background-color: $pink;
			color: #fff;
			margin-right: 15px;
		}
		.new-report-button {
			background-color: $orange;
            color: #fff;
		}

		button {
			font-size: 14px;
			border-radius: 2px;
			text-transform: uppercase;
			padding: 6px 16px;
			box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;

			&:hover {
				opacity: 0.75;
			}
		}
	}

  .user-box {
    button {
      margin-left: 10px;
      font-size: 14px;
      border-radius: 2px;
      text-transform: uppercase;
      padding: 6px 16px;
      color: #000000;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px,
        rgba(0, 0, 0, 0.12) 0px 1px 4px !important;

			&:hover {
				opacity: 0.75;
			}
		}
	}
}

.site-header {
	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}