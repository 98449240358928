.loader {
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;

	.message {
		margin-left: 2rem;
	}
}

.qrcode-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 40vw;
	border: 1px solid #000;
	background: #0003;
	padding: 2rem;

	@media screen and (max-width: 768px) {
		width: 60vw;
	}
}

.qrcode-svg {
	margin: 1rem auto;
	height: auto;
	width: 100%;
}